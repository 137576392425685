import { render, staticRenderFns } from "./Welcome.vue?vue&type=template&id=4922f908&"
import script from "./Welcome.vue?vue&type=script&lang=js&"
export * from "./Welcome.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseTypewriter: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Base/Typewriter.vue').default,CoreIcon: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Core/Icon/Icon.vue').default,BaseImage: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Base/Image/Image.vue').default,UiGridCard: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Ui/Grid/Card.vue').default})
