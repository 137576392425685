
export default {
  name: 'BaseTypewriter',
  props: {
    textArray: {
      type: Array,
      default: () => [],
    },
    colorArray: {
      type: Array,
      default: () => [],
    },
  },
  data: () => {
    return {
      typeValue: '',
      colorValue: '',
      typeStatus: false,
      typingSpeed: 100,
      erasingSpeed: 100,
      newTextDelay: 2000,
      displayTextArrayIndex: 0,
      displayColorArrayIndex: 0,
      charIndex: 0,
    }
  },
  created() {
    this.colorValue = this.colorArray[this.displayColorArrayIndex]
    setTimeout(this.typeText, this.newTextDelay + 200)
  },
  methods: {
    typeText() {
      if (this.charIndex < this.textArray[this.displayTextArrayIndex].length) {
        if (!this.typeStatus) this.typeStatus = true
        this.typeValue += this.textArray[this.displayTextArrayIndex].charAt(
          this.charIndex
        )
        this.charIndex += 1
        setTimeout(this.typeText, this.typingSpeed)
      } else {
        this.typeStatus = false
        setTimeout(this.eraseText, this.newTextDelay)
      }
    },
    eraseText() {
      if (this.charIndex > 0) {
        if (!this.typeStatus) this.typeStatus = true
        this.typeValue = this.textArray[this.displayTextArrayIndex].substring(
          0,
          this.charIndex - 1
        )
        this.charIndex -= 1
        setTimeout(this.eraseText, this.erasingSpeed)
      } else {
        this.typeStatus = false
        this.displayTextArrayIndex += 1
        this.displayColorArrayIndex += 1
        if (this.displayTextArrayIndex >= this.textArray.length) {
          this.displayTextArrayIndex = 0
        }
        if (this.displayColorArrayIndex >= this.colorArray.length) {
          this.displayColorArrayIndex = 0
        }
        this.colorValue = this.colorArray[this.displayColorArrayIndex]
        setTimeout(this.typeText, this.typingSpeed + 1000)
      }
    },
  },
}
