
const LIMIT = 5
export default {
  name: 'MegasredaAdvertusementWidgetsRecomendedBroadcasts',
  data() {
    return {
      broadcasts: [],
      otherBroadcasts: [],
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 10,
        breakpoints: {
          600: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          900: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
        },
        navigation: {
          nextEl: '.broadcast-slider__swiper-next',
          prevEl: '.broadcast-slider__swiper-prev',
        },
      },
    }
  },

  async fetch() {
    ;[this.broadcasts, this.otherBroadcasts] = await Promise.all([
      this.$axios.$get('/advertisements/recommended/broadcasts', {
        params: {
          limit: LIMIT,
        },
      }),

      this.$axios.$get('/advertisements/recommended/broadcasts', {
        params: {
          limit: 3,
          offset: LIMIT,
        },
      }),
    ])
  },
}
