import { render, staticRenderFns } from "./RecomendedBroadcasts.vue?vue&type=template&id=7abba826&"
import script from "./RecomendedBroadcasts.vue?vue&type=script&lang=js&"
export * from "./RecomendedBroadcasts.vue?vue&type=script&lang=js&"
import style0 from "./RecomendedBroadcasts.vue?vue&type=style&index=0&id=7abba826&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MegasredaContentBroadcastFeedCardSlide: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Megasreda/Content/Broadcast/FeedCardSlide.vue').default,CoreIcon: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Core/Icon/Icon.vue').default,UiGridCard: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Ui/Grid/Card.vue').default})
