var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-full flex max-w-layout mx-auto"
  }, [_c('BaseScroll'), _vm._v(" "), _c('div', {
    staticClass: "w-full tab:ml-12 max-w-content-full"
  }, [_vm.hasCoverSlot ? _c('div', {
    staticClass: "tab:mt-2.5 tab:rounded-xl overflow-hidden relative z-10"
  }, [_vm._t("cover")], 2) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "flex flex-wrap gap-5 tab-l:flex-nowrap w-full pt-2.5 pb-6"
  }, [_c('main', {
    staticClass: "w-full max-w-content-sm shrink-0 mx-0 phone-l:mx-auto tab-l:mx-0"
  }, [_vm._t("main")], 2), _vm._v(" "), _c('div', {
    staticClass: "aside w-full tab-l:max-w-none mx-0 phone-l:mx-auto tab-l:mx-0 tab-l:w-right-column mt-2.5 tab-l:mt-0 shrink-0"
  }, [_c('NuxtjsStickySidebar', {
    staticClass: "sidebar",
    attrs: {
      "top-spacing": _vm.topSpacing,
      "bottom-spacing": 24,
      "container-selector": ".aside"
    }
  }, [_vm._t("column")], 2)], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }