var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('MegasredaContentLayoutBase', {
    scopedSlots: _vm._u([{
      key: "cover",
      fn: function () {
        return [!_vm.$auth.loggedIn ? _c('MegasredaWelcome', {
          staticClass: "mb-2.5"
        }) : _vm._e(), _vm._v(" "), _c('MegasredaAdvertusementWidgetsRecomendedBroadcasts')];
      },
      proxy: true
    }, {
      key: "main",
      fn: function () {
        return [_c('PagesIndexNav', {
          staticClass: "mb-2.5",
          attrs: {
            "nav": [{
              id: 1,
              title: 'Медиа',
              path: '/recommendations'
            }, {
              id: 2,
              title: 'Видео',
              path: '/recommendations/video'
            }
            // {
            //   id: 3,
            //   title: 'События',
            //   path: '/recommendations/events',
            // },
            // {
            //   id: 4,
            //   title: 'Акции',
            //   path: '/recommendations/offers',
            // },
            ]
          }
        }), _vm._v(" "), _c('NuxtChild')];
      },
      proxy: true
    }, {
      key: "column",
      fn: function () {
        return [_c('LazyMegasredaRecomendedBaseBlock')];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }