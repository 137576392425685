
export default {
  data() {
    return {
      textArray: [
        'для людей в деле',
        'для бухгалтеров',
        'для юристов',
        'для руководителей',
      ],
      colorArray: ['#219653', '#EB5757', '#F2994A', '#9B51E0'],
      collections: [],
      usersCount: 2800,
      articlesCount: 9044,
      videosCount: 534,
      broadcastsCount: 47,
    }
  },
  async fetch() {
    const [
      collections,
      usersCount,
      articlesCount,
      videosCount,
      broadcastsCount,
    ] = await Promise.all([
      this.$axios.$get('/collection'),
      this.$axios.$get('user/count'),
      this.$axios.$get('articles/count/articles'),
      this.$axios.$get('articles/count/videos'),
      this.$axios.$get('advertisements/recommended/broadcasts/count'),
    ])
    this.collections = collections
    this.usersCount = usersCount
    this.articlesCount = articlesCount
    this.videosCount = videosCount
    this.broadcastsCount = broadcastsCount
  },
}
