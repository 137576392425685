import { render, staticRenderFns } from "./Base.vue?vue&type=template&id=766f2aab&"
import script from "./Base.vue?vue&type=script&lang=js&"
export * from "./Base.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseScroll: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Base/Scroll.vue').default})
