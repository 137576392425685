import { render, staticRenderFns } from "./recommendations.vue?vue&type=template&id=d417b232&"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MegasredaWelcome: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Megasreda/Welcome.vue').default,MegasredaAdvertusementWidgetsRecomendedBroadcasts: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Megasreda/Advertusement/Widgets/RecomendedBroadcasts.vue').default,PagesIndexNav: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Pages/Index/Nav.vue').default,MegasredaContentLayoutBase: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Megasreda/Content/Layout/Base.vue').default})
