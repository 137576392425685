
export default {
  name: 'PagesIndexNav',
  props: {
    nav: {
      type: Array,
      default: () => [
        {
          id: 1,
          title: 'Материалы',
          path: '/',
        },
        {
          id: 2,
          title: 'События',
          path: '/i/events',
        },
        {
          id: 3,
          title: 'Акции',
          path: '/i/offers',
        },
        {
          id: 4,
          title: 'Продукты',
          path: '/i/goods',
        },
      ],
    },
  },
}
